import React from 'react';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import { FormattedMessage } from 'gatsby-plugin-intl';
import useIsomorphicLayoutEffect from "react-use/esm/useIsomorphicLayoutEffect";
import { useUri } from '@/common/hooks';
import { jsx as ___EmotionJSX } from "@emotion/core";

var TvRedirectPage = function TvRedirectPage() {
  var _useUri = useUri(),
      uriHelper = _useUri.uriHelper;

  useIsomorphicLayoutEffect(function () {
    window.location.assign(uriHelper.getTvConnectUrl());
  }, [uriHelper]);
  return ___EmotionJSX("span", null, "Redirecting to", ___EmotionJSX(OutboundLink, {
    href: uriHelper.getTvConnectUrl()
  }, ___EmotionJSX(FormattedMessage, {
    id: "profile.tvConnect"
  })));
};

TvRedirectPage.displayName = "TvRedirectPage";
export { TvRedirectPage as default };